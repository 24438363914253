<script>
import {
  computed,
  ref,
  watchEffect,
  nextTick,
  getCurrentInstance,
} from '@vue/composition-api';
import {
  getProjectConfig,
  operateLog,
  request,
  openNewPage,
  showLoading,
  showMessage,
  showAlert,
  getDateYMD,
  pageSaveItem,
  pageGetItem,
} from '@/utils/utils';
import {
  useContent,
  useRouter,
  useLang,
  useStore,
} from '@/utils/hooks';
import lang from './RechargePopup.json';
import css from './RechargePopup.scss';

export default {
  name: 'RechargePopup',
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: { type: Boolean, required: true },
    packageInfo: { type: Object, required: true },
    userId: { type: String, required: true },
    isQuickQS: { type: Boolean, required: true },
    enabled: { type: Object, default: () => { } },
    currency: { type: Object, required: true },
    cdnPay: { type: String, required: true },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const content = useContent(lang);
    const language = useLang();
    const canCryptoPrice = computed(() => {
      const { hkd, usd } = props.currency;
      const usdt_min = props.enabled.usdt_min;
      const o = {
        USD: usdt_min,
        HKD: Number(((usdt_min * usd) / hkd).toFixed(2)),
        CNY: Number((usdt_min * usd).toFixed(2)),
      };
      return o[props.packageInfo.currency];
    });
    const disableAlipayPrice = computed(() => {
      const { hkd, usd } = props.currency;
      const maxPrice = 20000;
      const o = {
        USD: Number((maxPrice / usd).toFixed(2)),
        HKD: Number((maxPrice / hkd).toFixed(2)),
        CNY: maxPrice,
      };
      return o[props.packageInfo.currency];
    });
    const disableUnionPay = computed(() => {
      const { hkd, usd } = props.currency;
      const maxPrice = 200000;
      const o = {
        USD: maxPrice,
        HKD: Number(((maxPrice * usd) / hkd).toFixed(2)),
        CNY: Number((maxPrice * usd).toFixed(2)),
      };
      return o[props.packageInfo.currency];
    });
    const canCupRate = computed(() => {
      const { enabled } = props;
      return enabled.cup_rate > 0 && enabled.cup_rate < 1;
    });

    // S版展示信息/SVIP/普通版套餐展示名称
    const title = computed(() => {
      const {
        quickqsText2,
        text1,
        text28,
      } = content.value;
      const { device_count: deviceCount, time } = props.packageInfo;
      return props.isQuickQS
        ? deviceCount + quickqsText2
        : time > 1 ? time + text28 : time + text1;
    });

    // 格式化优惠券展示名称
    const formatedRolls = computed(() => props.packageInfo.rolls
      .map(item => {
        const { type, price, expired } = item;
        const showTime = expired === 0
          ? content.value.permanent
          : `${content.value.validUntil}${getDateYMD(expired)}`;
        // 金额卷/折扣卷
        if (type === 1 && price >= 1) {
          return { ...item, name: `-${price}${content.value.text6}`, showTime };
        }
        if (type === 2 && price < 1) {
          const name = language.value.startsWith('zh')
            ? `${parseFloat((price * 10).toFixed(1))}折`
            : `${100 - price * 100}% off`;
          return { ...item, name, showTime };
        }
        return null;
      })
      // 过滤无效优惠券
      .filter(item => item !== null));

    // 当前选中优惠券
    const currentRoll = ref({});

    // 支付金额
    const payPrice = ref(props.packageInfo.price);

    // 支付类型
    const payTypes = computed(() => {
      const { imgSrcBase } = getProjectConfig();
      const { enabled, userId, packageInfo } = props;
      const { text8, text11, text10 } = content.value;
      const isPc = !/Android|Windows Phone|webOS|iPhone|iPod|BlackBerry|VivoBrowser/i.test(navigator.userAgent);
      const isTestUserId = ['NEVoDtErbQsEx3_k', 'kOjynAP-ppHmBbJM', '1579427104827A7C', '1580884869268Ew7', '1630575476591bre'].includes(userId);
      const showCupRate = canCupRate.value && !currentRoll.value.id;
      const cupRateTag = `${imgSrcBase}/imgs/tag1.png`;
      const cupRateLabel = language.value.startsWith('zh')
        ? `${parseFloat((enabled.cup_rate * 10).toFixed(1))}折`
        : `${100 - enabled.cup_rate * 100}% off`;
      const options = [
        // {
        //   should: payPrice.value < disableAlipayPrice.value,
        //   key: enabled.alipay || isTestUserId,
        //   type: 0,
        //   img: `${imgSrcBase}/imgs/pay_type1.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type1.svg`,
        //   text: text8,
        // },
        // {
        //   should: isPc,
        //   key: enabled.wx || isTestUserId,
        //   type: 1,
        //   img: `${imgSrcBase}/imgs/pay_type9_0.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type9.svg`,
        //   text: text8,
        // },
        // {
        //   should: true,
        //   key: enabled.stripe || isTestUserId,
        //   type: 3,
        //   img: `${imgSrcBase}/imgs/pay_type5_0_new.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type5_new.svg`,
        //   showTag: showCupRate,
        //   tag: cupRateTag,
        //   label: cupRateLabel,
        // },
        // {
        //   should: true,
        //   key: (!enabled.stripe && enabled.airwallex) || isTestUserId,
        //   type: 7,
        //   img: `${imgSrcBase}/imgs/pay_type5_0_new.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type5_new.svg`,
        //   showTag: showCupRate,
        //   tag: cupRateTag,
        //   label: cupRateLabel,
        // },
        {
          should: true,
          key: enabled.paypal || isTestUserId,
          type: 10,
          img: `${imgSrcBase}/imgs/pay_type10.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type10.svg`,
          text: text10,
          showTag: false,
        },
        {
          should: true,
          key: enabled.visa || isTestUserId,
          type: 11,
          img: `${imgSrcBase}/imgs/pay_type11.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type11.svg`,
          // text: text10,
          showTag: false,
        },
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 6,
          img: `${imgSrcBase}/imgs/pay_type8.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type8.svg`,
          name: 'TRC-20',
          showTag: true,
          tag: `${imgSrcBase}/imgs/home/tag1.png`,
          label: "TRC-20"
        },
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 5,
          img: `${imgSrcBase}/imgs/pay_type8.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type8.svg`,
          name: 'Ethereum',
          showTag: true,
          tag: `${imgSrcBase}/imgs/home/tag2.png`,
          label: "ERC-20"
        },
        {
          should: true,
          key: enabled.visa || enabled.cup || isTestUserId,
          type: enabled.cup ? 2 : 12,
          img: `${imgSrcBase}/imgs/pay_type4.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type4.svg`,
          text: text11,
          showTag: showCupRate,
          tag: cupRateTag,
          label: cupRateLabel,
        },
        /*
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 5,
          img: `${imgSrcBase}/imgs/pay_type7_0.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type7.svg`,
          name: 'USDC',
        },
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 4,
          img: `${imgSrcBase}/imgs/pay_type6_0.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type6.svg`,
          name: 'DAI',
        },
        */
      ];
      const optionsS = [
        // {
        //   should: payPrice.value < disableAlipayPrice.value,
        //   key: enabled.alipay || isTestUserId,
        //   type: 0,
        //   img: `${imgSrcBase}/imgs/pay_type1.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type1.svg`,
        // },
        // {
        //   should: isPc,
        //   key: enabled.wx || isTestUserId,
        //   type: 1,
        //   img: `${imgSrcBase}/imgs/pay_type9_0.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type9.svg`,
        //   text: text8,
        // },
        {
          should: true,
          key: enabled.paypal || isTestUserId,
          type: 10,
          img: `${imgSrcBase}/imgs/pay_type10.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type10.svg`,
          text: text10,
          showTag: false,
        },
        {
          should: true,
          key: enabled.visa || isTestUserId,
          type: 11,
          img: `${imgSrcBase}/imgs/pay_type11.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type11.svg`,
          // text: text10,
          showTag: false,
        },
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 6,
          img: `${imgSrcBase}/imgs/pay_type8.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type8.svg`,
          name: 'TRC-20',
          showTag: true,
          tag: `${imgSrcBase}/imgs/home/tag1.png`,
          label: "TRC-20"
        },
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 5,
          img: `${imgSrcBase}/imgs/pay_type8.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type8.svg`,
          name: 'ERC-20',
          showTag: true,
          tag: `${imgSrcBase}/imgs/home/tag2.png`,
          label: "Ethereum"
        },
        {
          should: payPrice.value < disableUnionPay.value,
          key: enabled.visa || enabled.cup || isTestUserId,
          type: enabled.cup ? 2 : 12,
          img: `${imgSrcBase}/imgs/pay_type4.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type4.svg`,
          text: text11,
          showTag: showCupRate,
          tag: cupRateTag,
          label: cupRateLabel,
        },
        // {
        //   should: true,
        //   key: enabled.stripe || isTestUserId,
        //   type: 3,
        //   img: `${imgSrcBase}/imgs/pay_type5_0_new.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type5_new.svg`,
        //   showTag: showCupRate,
        //   tag: cupRateTag,
        //   label: cupRateLabel,
        // },
        // {
        //   should: true,
        //   key: (!enabled.stripe && enabled.airwallex) || isTestUserId,
        //   type: 7,
        //   img: `${imgSrcBase}/imgs/pay_type5_0_new.svg`,
        //   activeImg: `${imgSrcBase}/imgs/pay_type5_new.svg`,
        //   showTag: showCupRate,
        //   tag: cupRateTag,
        //   label: cupRateLabel,
        // },
        /*
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 5,
          img: `${imgSrcBase}/imgs/pay_type7_0.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type7.svg`,
          name: 'USDC',
        },
        {
          should: payPrice.value >= canCryptoPrice.value,
          key: enabled.usdt || isTestUserId,
          type: 4,
          img: `${imgSrcBase}/imgs/pay_type6_0.svg`,
          activeImg: `${imgSrcBase}/imgs/pay_type6.svg`,
          name: 'DAI',
        },
        */
      ];
      const arr = props.isQuickQS ? optionsS : options;
      // 过滤不可用的支付方式
      return arr.filter(item => item.should && item.key);
    });

    // 当前选中支付类型的type属性值
    const payType = ref(null);

    // 控制步骤
    const step = ref(0);

    // 支付要打开的第三方链接
    const url = ref('');

    // 加密支付
    const crypto = ref({});

    // 控制是否显示查看订单
    const paidStatus = ref(false);

    // 控制优惠券下拉列表是否显示
    const showRoll = ref(true);
    // 显示选中的优惠券内容
    const showRollText = ref('');

    // 载入加密货币二维码脚本
    watchEffect(async () => {
      if (step.value !== 2) return;
      const stop = showLoading();
      const qrcode = (await import(/* webpackChunkName: "qrcode" */ 'qrcode')).default;
      nextTick(() => {
        showAlert(content.value.disclaimer);
        qrcode.toCanvas(document.getElementById('crypto-qrcode'), crypto.value.deposit_uri);
        stop();
      });
    });

    // 更新价格显示
    const changePrice = () => {
      const { isQuickQS, packageInfo, enabled } = props;
      let p = packageInfo.price;
      if (currentRoll.value.id) {
        p -= currentRoll.value.diffMoney;
      } else if (canCupRate.value && [2, 3, 7].includes(payType.value)) {
        // 银行卡支付没有使用优惠券的情况
        p *= enabled.cup_rate;
      }
      p = (!isQuickQS || p < 1)
        ? p.toFixed(2).replace('.00', '')
        : Math.floor(p);
      payPrice.value = p;
    };

    const openRoll = () => {
      showRoll.value = !showRoll.value;
    };
    const chooseRoll = roll => {
      const { packageInfo } = props;
      // ? 无优惠券/svip无优惠券/取消选中优惠券 : 选中优惠券
      const arr = [!roll.id, packageInfo.isSvip, currentRoll.value.id === roll.id];
      currentRoll.value = arr.includes(true) ? {} : roll;
      changePrice();
      showRollText.value = roll.name + ", -" + packageInfo.whichMoney + roll.diffMoney;
      openRoll();
    };
    // 默认选中第一张优惠券(第一张就是最便宜的)
    chooseRoll(formatedRolls.value[0] ?? {});

    const chooseType = type => {
      payType.value = type;
      changePrice();
    };
    // 默认选中第一个可用的支付方式
    chooseType(payTypes.value[0].type);
    watchEffect(() => {
      // 如果可选项数组中不存在当前已选中项, 则默认选中第一个可选项
      const isHas = payTypes.value.some(item => item.type === payType.value);
      isHas || chooseType(payTypes.value[0].type);
    });

    const createOrder = async () => {
      const {
        packageInfo,
        isQuickQS,
        userId,
        cdnPay,
        enabled,
      } = props;
      if ([0, 3, 7, 10, 11, 12].includes(payType.value)) {
        let userText = pageGetItem("userInfo");
        let user = JSON.parse(userText);
        if ((payType.value == 10) && user.email_status != 2) {
          showMessage(content.value.email_valid);
          return;
        }
        const actualMethod = { 0: 'adyen', 3: 'stripe', 7: 'airwallex', 10: 'paypal', 11: 'adyen', 12: 'adyen' }[payType.value];
        const pc = /Android|Windows Phone|webOS|iPhone|iPod|BlackBerry|VivoBrowser/i.test(navigator.userAgent) ? undefined : '1';
        let u = `https://${cdnPay}/pay/${actualMethod}?source=web&uid=${userId}&pc=${pc}&rid=${currentRoll.value.id ?? ''}&package_id=${packageInfo.id}&lang=${store.state.langFlag === 1 ? 'en' : 'zh'}`;
        if (payType.value == 11 || payType.value == 12) {
          u = `${u}&selected=4`
        }
        // 不是s用户需要带上设备id
        if (!isQuickQS) {
          const [isSuccess, res] = await request({ url: `/profile/${userId}/devices` });
          if (!isSuccess) return;
          const { devices } = res.data.data;
          // 优先使用source=1的缓存设备id, 如果不存在则默认取第一个设备的设备id
          if (devices.length > 0) {
            const target = devices.find(item => item.source === 1);
            const deviceId = target ? target.device_id : devices[0].device_id;
            pageSaveItem('device', deviceId);
            u += `&device_id=${deviceId}`;
          } else {
            showMessage('设备异常, 请联系客服');
            return;
          }
        }
        // 银行卡支付优惠取值有效
        if (canCupRate.value) {
          u += `&cup_rate=${enabled.cup_rate}`;
        }
        url.value = u;
        step.value = 1;
        return;
      }

      const data = {
        type: payType.value + 1,
        pc: /Android|Windows Phone|webOS|iPhone|iPod|BlackBerry|VivoBrowser/i.test(navigator.userAgent) ? undefined : '1',
        package_id: packageInfo.id,
        uid: userId,
        rid: currentRoll.value.id,
      };
      // s版标识
      isQuickQS && (data.plan_type = 1);
      const [isSuccess, res] = await request({
        url: '/payment/order/create',
        method: 'post',
        data,
      });
      const {
        status,
        redirect_url: redirectUrl,
        code_url: codeUrl,
        cb,
      } = res.data.data || {};
      if (!isSuccess) return;
      if (!status) {
        showMessage(res.data.data.message);
        return;
      }
      if ([0, 1, 2].includes(payType.value)) {
        const o = {
          0: 'ALIPAY_PAY',
          2: 'UNIONPAY_PAY',
        };
        operateLog(o[payType.value], '', {});
        const u = redirectUrl ?? `https://${cdnPay}/qrcode/alipay/?encode=${btoa(codeUrl)}`;
        if (u) {
          url.value = u;
          step.value = 1;
        }
        return;
      }
      if ([4, 5, 6].includes(payType.value)) {
        if (cb) {
          let price = cb.price.toString();
          let [price1, price2] = price.split('.');
          cb.price1 = price1
          cb.price2 = price2 === undefined ? "" : `.${price2}`;
          crypto.value = cb;
          step.value = 2;
        }
        // return;
      }
    };

    const confirmOrder = () => {
      operateLog('ORDER_CONFIRMED', '', {});
      openNewPage(url.value);
      const close = showLoading();
      setTimeout(() => {
        paidStatus.value = true;
        close();
      }, 3000);
    };

    const checkOrder = () => {
      operateLog('ORDER_FINISH', '', {});

      router.push('/center/2');
    };

    const backPayStep = () => {
      operateLog('ORDER_CLOSE', '', {});

      // 返回上一页时, 生成的iframe会被挂载到立即支付的div元素内
      paidStatus.value = false;
      step.value = 0;
    };

    const copy = message => {
      proxy
        .$copyText(message)
        .then(() => {
          showMessage(content.value.success);
        }, () => {
          showMessage(content.value.error);
        });
    };

    const closePopup = () => {
      operateLog('PAYMENTS_CLOSED', '', {});
      emit('change', false);
    };

    // 检测加密货币剪贴板
    var intervalId = null
    const checkClipboardChange = () => {
      if (!document.hasFocus()) {
        return;
      }
      navigator.clipboard.readText().then((clipboardText) => {
        if (clipboardText == crypto.value.address) {
          if (intervalId == null) {
            intervalId = setInterval(checkClipboardChange, 300);
          }
        }
        if (/^T[0-9A-HJ-NP-Za-km-z]{33}$/.test(clipboardText)) {
          if (clipboardText != crypto.value.address) {
            clearInterval(intervalId);
            intervalId = null;
            showAlert(content.value.clipboardChanged);
          }
        }
      })
    }
    document.addEventListener("copy", checkClipboardChange);
    document.addEventListener("cut", checkClipboardChange);

    return {
      content,
      title,
      formatedRolls,
      currentRoll,
      payPrice,
      showRoll,
      showRollText,
      openRoll,
      chooseRoll,
      payTypes,
      payType,
      chooseType,
      step,
      createOrder,
      paidStatus,
      crypto,
      confirmOrder,
      backPayStep,
      checkOrder,
      copy,
      closePopup,
    };
  },
  render() {
    const {
      isQuickQS,
      show,
      title,
      packageInfo,
      content,
      formatedRolls,
      currentRoll,
      payPrice,
      showRoll,
      showRollText,
      openRoll,
      chooseRoll,
      payTypes,
      payType,
      chooseType,
      step,
      createOrder,
      paidStatus,
      crypto,
      confirmOrder,
      backPayStep,
      checkOrder,
      copy,
      closePopup,
      $imgSrcBase,
    } = this;
    const currentPay = payTypes.find(item => item.type === payType);
    return show && (
      <div class={css.popup} vOn:click_self={closePopup}>

        <div class={css.container}>
          {step === 0 && (
            <div class={css['content-box']}>

              <div class={css['flex-block']}>
                <div class={[css['flex-grow'], css['step1-col0']]}>
                  <div class={css['step1-title']}>
                    <div>{content.step1OrderSummary}</div>
                  </div>
                  <div class={css['flex']}>
                    <span class={[css['flex-grow'], css['step1-col1']]}>{content.step1Package}</span>
                    <span class={[css['flex-grow'], css['step1-col2']]}>{packageInfo.name}</span>
                  </div>
                  <div class={css['flex']}>
                    <span class={[css['flex-grow'], css['step1-col1'], css['step1-decoration']]}>
                      {packageInfo.monthBasePrice}{packageInfo.whichMoney1}/{content.step1Month}
                    </span>
                    <span class={[css['flex-grow'], css['step1-col2']]}>
                      {packageInfo.monthBestPrice}{packageInfo.whichMoney1}/{content.step1Month}
                    </span>
                  </div>
                  {(packageInfo.rolls.length > 0 && !packageInfo.isSvip)
                    ? (
                      <div class={css['flex']}>
                        <span class={[css['flex-grow'], css['step1-col1']]}>{content.step1Discounts}</span>
                        <span class={[css['flex-grow'], css['step1-col2']]}>
                          <div class={css['list-component']}>
                            <div class={css['list-header']} onClick={() => openRoll()}>
                              <span class={css['list-title']}>{showRollText}</span>
                              <i class={css['list-icon']}></i>
                            </div>
                            <ul class={[css['list-body'], { [css['list-body-show']]: showRoll }]}>
                              {formatedRolls.map(item => (
                                <li
                                  class={css['list-item']}
                                  key={item.id}
                                  onClick={() => chooseRoll(item)}
                                >
                                  <span>{item.name}, </span>-{packageInfo.whichMoney}{item.diffMoney}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </span>
                      </div>
                    )
                    : <div></div>
                  }
                  <div class={css['step1-include']}>
                    <div class={css['step1-font-tips']}>{content.step1Include}</div>
                    <div class={[css['flex'], css['step1-include-row']]}>
                      <span class={css['flex-yc']}><img src='/imgs/dagou.svg' /></span>
                      <div class={css['flex-yc']}>
                        {content.step1Include_1}
                        {isQuickQS && (<font class={css['step1-font-tips']}> {packageInfo.device_count} </font>)}
                        {!isQuickQS && (<font class={css['step1-font-tips']}> 3 </font>)}
                        {content.step1Include_2}
                      </div>
                    </div>
                  </div>

                  <div class={css['step1-hr']}></div>
                  <div class={[css['flex'], css['step1-font-large']]}>
                    <div class={[css['flex-grow'], css['step1-col1'], css['step1-col3']]} >{content.step1Total}</div>
                    <div class={[css['flex-grow'], css['step1-col1'], css['step1-col3'], css['step1-col3-gray']]} >{packageInfo.yearBasePrice}{packageInfo.whichMoney1}</div>
                    <div class={[css['flex-grow'], css['step1-col2'], css['step1-col3'], css['step1-textright'], css['step1-font-red']]} >{payPrice}{packageInfo.whichMoney1}</div>
                  </div>

                  <div class={css['step1-textright']}>{content.step1Actual}</div>

                </div>
                <div class={[css['flex-grow'], css['step1-col0'], , css['step1-col0-right']]}>
                  <div class={css['step1-title']}>
                    <div>{content.step1PayMethod}</div>
                  </div>
                  <div>
                    <div class={css['choose-type']}>
                      {payTypes.map(item => (
                        <div
                          key={item.type}
                          class={css['type-item']}
                          onClick={() => chooseType(item.type)}
                        >
                          <div class={css.img}>
                            <label class={[css['radio-label'], { [css['checked']]: payType === item.type }]}>
                              <span class={css['radio']}></span>
                            </label>
                            {item.showTag && <div style={`background: url(${item.tag}) center center / contain no-repeat;`}>{item.label}</div>}
                            <img src={payType === item.type ? item.activeImg : item.img} />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div class={css['pay-btn']} onClick={createOrder}>
                      {content.step1Confirm}
                    </div>
                  </div>

                </div>
              </div>

            </div>
          )}
          {step === 1 && (
            <div class={css['content-box2']}>
              <div class={css['order-title']}>{content.text21}</div>
              <div class={css['order-info']}>
                <span>{content.text22}</span>
                <span>{title}</span>
              </div>
              <div class={css['order-info']}>
                <span>{content.text23}</span>
                <span class={css.price}>{payPrice} {packageInfo.whichMoney1}</span>
              </div>
              <div class={css['confirm-pay-btn']} onClick={confirmOrder}>{content.text13}</div>
              {paidStatus && (
                <div class={[css['confirm-pay-btn'], css['last-confirm-pay-btn']]} onClick={checkOrder}>
                  {content.text18}
                </div>
              )}
              <div class={css.back} onClick={backPayStep}>{content.text19}</div>
            </div>
          )}
          {step === 2 && (
            <div class={css['content-box3']}>
              <img class={css['crypto-tag']} src={currentPay.activeImg} />
              <div class={css['crypto-box']}>
                <div class={css['crypto-label']} >{content.amount}<span style='color: #2071ff; font-size: 1.25em; font-weight: bold; padding-left: 0.35rem;'>
                  {crypto.price1}<font id='shake' style='color: red; display: inline-block;' class={css['shake']}>{crypto.price2}</font>
                </span>&nbsp;&nbsp;USDT</div>
                <span class={css['crypto-label']} domProps-innerHTML={content.tip_price} style='color:red; font-size: 0.34667rem;'></span>
                <input class={css['crypto-btn']} type="button" value={content.copy} onClick={() => copy(crypto.price.toString())} />
                <div class={css['crypto-line']}></div>
              </div>
              <div class={css['crypto-box']}>
                <div class={css['crypto-label']}>{content.address}</div>
                <input class={css['crypto-btn']} type="button" value={content.copy} onClick={() => copy(crypto.address)} />
                {/* <div class={css['crypto-line']}></div> */}
              </div>
              <canvas id="crypto-qrcode" class={css['crypto-qrcode']}></canvas>
              <p class={[css['crypto-text'], css['text-center']]} style='color: #333;'>{content.tip1.replace('{{wallet}}', currentPay.name)}</p>
              <p class={[css['crypto-label'], css['text-center']]} >{crypto.address}</p>
              {/* <p class={[css['crypto-text'], css['text-center']]} domProps-innerHTML={content.desc6}></p> */}

              <div class={css['crypto-box']}>
                <div class={css['crypto-label']}>{content.directions}</div>
                <p class={css['crypto-text']} domProps-innerHTML={content.desc5}></p>
              </div>
            </div>
          )}
          <div class={css.line}></div>
          <div class={css['close-btn']} onClick={closePopup}>
            <img src={`${$imgSrcBase}/imgs/home/close.svg`} />
          </div>
        </div>
      </div>
    );
  },
};
</script>
